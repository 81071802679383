<template>
  <DeleteInformationPartsTable title="Список отчётов" :dipa-id="dipaId"/>
</template>

<script>
import DeleteInformationPartsTable from "@/components/tables/DeleteInformationPartsTable.vue";

export default {
  name: 'DipaReportsTableDialog',
  components: {DeleteInformationPartsTable},
  inject: ['dialogRef'],
  data() {
    return {
      dipaId: null
    }
  },
  beforeMount() {
    const params = this.dialogRef.data;
    this.dipaId = params.dipaId;
  }
}
</script>