<template>
  <SplitButton
    :label="this.id.toString()"
    :model="generateItems()"
    class="p-button-sm p-button-rounded p-button-primary mb-2"
    @click="copy"
  />
</template>

<script>
export default {
  name: 'IdChip',
  props: {
    id: {
      type: [Number, String],
      required: true
    },

    view: {
      type: String,
      default: () => null
    },
    viewDirectLabel: {
      type: String,
      default: () => 'Перейти'
    },
    viewNewTabLabel: {
      type: String,
      default: () => 'Открыть в новой вкладке'
    },
    viewQuery: {
      type: Object
    },
    viewParams: {
      type: Object
    },

    menuItems: {
      type: Array,
      default: () => []
    },
    toastTemplate: {
      type: String,
      default: () => 'Значение "{v}" скопировано'
    }
  },
  methods: {
    generateItems() {
      let items = [];
      if (this.view !== null) {
        items.push(
          {
            label: this.viewDirectLabel,
            icon: 'pi pi-fw pi-forward',
            command: () => this.pushToView()
          },
          {
            label: this.viewNewTabLabel,
            icon: 'pi pi-fw pi-forward',
            command: () => this.pushToView(true)
          },
          { separator: true }
        );
      }
      if (this.menuItems.length > 0) {
        this.menuItems.forEach(item => items.push(item));
        items.push({ separator: true });
      }
      items.push({
        label:'Закрыть',
        icon:'pi pi-fw pi-times'
      });
      return items;
    },
    copy() {
      navigator.clipboard.writeText(this.id)
      this.$toast.add({
        severity: 'success',
        summary: 'Значение скопировано',
        detail: this.toastTemplate.replaceAll('{v}', this.id),
        life: 2000
      })
    },
    pushToView(newTab) {
      const params = this.viewParams ?? {
        id: this.id
      }
      const query = this.viewQuery;

      if (newTab) {
        let route = this.$router.resolve({
          name: this.view,
          params,
          query
        })
        window.open(route.href, '_blank')
        return
      }
      this.$router.push({
        name: this.view,
        params,
        query
      })
    }
  }
}
</script>


